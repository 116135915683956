<template lang='pug'>
  section#features
    .container.mt-15.mb-15
      .row.px-4
        .col-md-6.mx-auto.text-center
          h2.success--text.font-weight-bold Preguntas Frecuentes.
          p.font-weight-light
            | Respuestas a las preguntas mas habituales
      .row
        .col-md-6.px-10(v-for="(item,i) in cuestiones" :key="i")
          h5
            | {{ item.pregunta }}
          p.font-weight-light
            | {{ item.respuesta }}
    router-view
</template>
<script>
export default {
  data () {
    return {
      cuestiones: [
        {
          pregunta: '¿Cómo se verifica la autenticidad del certificado?',
          respuesta: 'Una vez concluido el curso, se asigna un código(ID) de certificado por cada curso que ha completado, dirígete al icono CERTIFICADO que se encuentra en la esquina superior derecha de la página, ingresa el código y dale click al botón "Buscar ID", en su pantalla aparecerá los datos. Esto permite verificar la autenticidad de certificado.'
        },
        {
          pregunta: '¿Dónde publican los cursos, cómo me entero acerca de los próximos lanzamientos?',
          respuesta: 'Enterate de los nuevos cursos a través de nuestras redes sociales o puedes contactarnos haciendo click en el botón "contáctanos" o los iconos de redes sociales en la parte inferior de la página.'
        },
        {
          pregunta: '¿Cuáles son los modos de pago de un curso?',
          respuesta: 'Existen tres métodos de pago. Depósito bancario, Transferencia a cuenta bancaria y Tigo Money.  Se recomienda previamente coordinar el método de pago.'
        },
        {
          pregunta: '¿Qué es trunable?',
          respuesta: 'Somos una institución que brinda capacitación virtual enfocada en áreas STEAM(Ciencia, Tecnología, Ingeniería, Arte y Matemática). Trunable es un emprendimiento virtual.'
        }
      ]
    }
  }
}
</script>
<style>
</style>
