<template lang='pug'>
  section#features
    .container.mt-15.mb-15
      .row.px-4
        .col-md-6.mx-auto.text-center
          h2.primary--text.font-weight-bold Cursos ofertados
          p.font-weight-light
            | 100% online con instructores de amplia experencia
      .row
        .col-md-4.px-10(v-for="(item,i) in items" :key="i")
          v-card.mx-auto
            v-img.align-end(
              :src="item.src"
            )
            v-card-text.pb-0
              | Nivel: {{ item.nivel }}
            v-card-title(style="word-break: normal").py-0
              | {{ item.alt }}
            v-card-title.py-0.font-weight-black.accent--text
              | {{ item.focus }}
            v-card-actions.d-flex.justify-end
              v-btn(color="success" text href="https://wa.me/+59178316724/?text=Deseo mas infomación" target="_blank" rel="noopener")
                v-icon.mx-1(small) mdi-whatsapp
                | Más info
      .row.mt-10
        .col-md-6.mx-auto.text-center
          h5 Para mas información
          v-btn(color="primary" href="https://wa.me/+59178316724/?text=Deseo mas infomación" target="_blank" rel="noopener") Click aquí
    router-view
</template>
<script>
export default {
  data () {
    return {
      items: [
        {
          src: require('../assets/img/c_01.jpg'),
          alt: 'Programción de Videojuegos',
          focus: 'SCRATCH',
          nivel: 'Básico'
        },
        {
          src: require('../assets/img/c_02.jpg'),
          alt: 'Programación de Videojuegos',
          focus: 'ROBLOX',
          nivel: 'Básico'
        },
        {
          src: require('../assets/img/c_03.jpg'),
          alt: 'Programación de Videojuegos',
          focus: 'MINECRAFT',
          nivel: 'Básico'
        },
        {
          src: require('../assets/img/c_04.jpg'),
          alt: 'Introdución a la pintura',
          focus: 'ACUARELAS',
          nivel: 'Básico'
        },
        {
          src: require('../assets/img/c_05.jpg'),
          alt: 'Word, PowerPoint, Excel',
          focus: 'OFIMÁTICA',
          nivel: 'Básico'
        },
        {
          src: require('../assets/img/c_06.jpg'),
          alt: 'Robótica',
          focus: 'ARDUINO',
          nivel: 'Básico'
        },
        {
          src: require('../assets/img/c_07.jpg'),
          alt: 'Jaquemate',
          focus: 'AJEDRES',
          nivel: 'Básico'
        },
        {
          src: require('../assets/img/c_08.jpg'),
          alt: 'Introduccion Programación',
          focus: 'PYTHON',
          nivel: 'Básico'
        },
        {
          src: require('../assets/img/c_09.jpg'),
          alt: 'Diseño Gráfico Vectorial',
          focus: 'AFFINITY DESIGNER',
          nivel: 'Básico'
        },
        {
          src: require('../assets/img/c_10.jpg'),
          alt: 'Diseño Gráfico - Juniors',
          focus: 'AFFINITY DESIGNER',
          nivel: 'Básico'
        }
      ]
    }
  }
}
</script>
<style>
</style>
