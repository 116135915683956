var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"fixed":"","color":"white"}},[_c('v-app-bar-title',[_c('router-link',{attrs:{"img":"","to":"/"}},[_c('v-img',{attrs:{"width":"180px","height":"auto","background-size":"cover","src":require("../assets/img/logo.svg"),"contain":""}})],1)],1),_c('v-spacer'),_c('div',{staticClass:"hidden-sm-and-down"},_vm._l((_vm.items),function(item){return _c('v-menu',{key:item.id,scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","icon":"","to":item.url}},'v-btn',attrs,false),Object.assign({}, tooltip)),[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.text))])])]}}],null,true)})}),1),_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-lg-none d-md-none",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}])},[_c('v-list',[_c('v-list-item-group',{model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.id,attrs:{"to":item.url}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1)],1)],1)],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }