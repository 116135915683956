<template lang='pug'>
   section
    .container.mt-15.mb-15
      .row.px-4
        .col-md-6.mx-auto.text-center
          h4 Verificación de certificado
          .caption.primary--text
            | Para verificar la autenticidad de tu certificado,
            | ingresa el ID ubicado en la parte superior derecha.
            | Si aun no tienes tu certificado puedes usar el codigo
            spam.font-weight-black  DEMOCERT
            |  para ver como sera cuando lo tengas.
          v-form(ref="form" v-model="valido")
            v-layout.column
              v-flex
                v-text-field(label="ID" :rules="rules" hide-details="auto" required='' v-model="buscar")
              v-flex.text-xs-center.mt-5
                v-btn.mr-4(color="primary" @click="real" center :disabled="!valido") Buscar ID
                v-btn.outlined(color='pink' dark @click="reset") Refrescar
      .row
        .col-md-6.mx-auto.text-center
          cer(v-bind:c="carton" v-if="cer")
</template>

<script>
import estudiantes from '@/assets/json/cursos.json'
import cer from '@/components/certificate.vue'
export default {
  name: 'Search',
  components: {
    cer
  },
  data () {
    return {
      estudiantes,
      carton: {},
      buscar: '',
      rules: [
        value => !!value || 'El código es requerido.',
        value => (value && value.length >= 4) || 'Min 4 characters'
      ],
      valido: false,
      cer: false
    }
  },
  methods: {
    reset () {
      this.$refs.form.reset()
      this.cer = false
    },
    real () {
      try {
        if (this.buscar.toUpperCase() === estudiantes.filter(item => { return item.id.toLowerCase().includes(this.buscar.toLowerCase()) })[0].id) {
          this.carton = estudiantes.filter(item => { return item.id.toLowerCase().includes(this.buscar.toLowerCase()) })[0]
          // console.log('fruko', this.carton)
          this.cer = true
          return this.carton
        } else {
          throw new Error('algo paso')
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style>

</style>
