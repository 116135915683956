<template lang='pug'>
  v-footer(color='indigo accent-4' padless='')
    .container.mt-15.mb-15
      .row.mt-10
        .col-md-6.mr-auto
          p.overline.white--text
            | Todo los cursos están orientados a estimular el desarrollo intelectual a través de áreas STEAM (Ciencia, Tecnología, Ingeniería, Arte y Matemática).
        .col-md-6
          .d-flex.justify-center
            a(icon href="https://fb.com/trunable" target="_blank" rel="noopener").mx-4.text-decoration-none
              v-icon( large color="white") mdi-facebook
            a(icon href="https://twitter.com/trunable" target="_blank" rel="noopener").mx-4.text-decoration-none
              v-icon( large color="white") mdi-twitter
            a(icon href="https://instagram.com/trunable" target="_blank" rel="noopener").mx-4.text-decoration-none
              v-icon( large color="white") mdi-instagram
            a(icon href="https://wa.me/+59178316724" target="_blank" rel="noopener").mx-4.text-decoration-none
              v-icon( large color="white") mdi-whatsapp
            a(icon href="https://www.tiktok.com/@trunable" target="_blank" rel="noopener").mx-4.text-decoration-none
              v-img(width="2rem" src="../assets/img/tiktok.svg")
    v-col.text-center.white--text(cols='12') Hecho con
      |
      |
      v-icon(mediun color="pink") mdi-heart
      |
      | TRUNABLE
</template>

<script>
export default {
  name: 'piePagina'
}
</script>
<style>
</style>
