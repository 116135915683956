<template lang='pug'>
  v-app
    Navbar
</template>

<script>
import Navbar from './components/navbar.vue'

export default {
  name: 'App',

  components: {
    Navbar
  },

  data: () => ({
    //
  })
}
</script>
<style>
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem; }
h1, .h1 {
  font-size : 6rem;
  font-weight: 300;
  letter-spacing: -.015625em;
  margin: -1;}
h2, .h2 {
  font-size : 3.75rem;
  font-weight: 300;
  letter-spacing: -.0083333333em;
  margin: -0;}
h3, .h3 {
  font-size : 3rem;
  font-weight: 400;
  letter-spacing: normal;
  margin: 1;}
h4, .h4 {
  font-size : 2.125rem;
  font-weight: 500;
  letter-spacing: .0073529412em;
  margin: 2;}
h5, .h5 {
  font-size : 1.5rem;
  font-weight: 400;
  letter-spacing: normal;
  margin: 2;}
h6, .h6 {
  font-size : 1.25rem;
  font-weight: 500;
  letter-spacing: .0125em;
  margin: 3;}

</style>
