<template lang='pug'>
  div
    v-card.mx-auto.text-center.pd
      v-img( :src ="c.img").white--text.text-right.white--text
        v-card-title.subtitle_1.flex-column.align-end ID: {{c.id}}
      h5.mt-4
        | {{c.curso}}
      p.subtitle-2.mt-4
        | TRUNABLE CERTIFICA QUE:
      h4.blue-grey--text
        | {{c.name}}
      p.body-2.mx-4.my-4(v-if="c.tipo==='instructor'")
        | Ha participado en calidad de instructor(a) del curso: {{c.curso}} completando todas las lecciones satisfactoriamente.
      p.body-2.mx-4.my-4(v-else)
        | Ha participado del curso: {{c.curso}} completando todas las lecciones satisfactoriamente.
      .overline.caption {{c.fecha}}
</template>

<script>
export default {
  name: 'cer',
  props: {
    c: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>

</style>
