<template lang='pug'>
  div
    v-app-bar(fixed color='white')
      v-app-bar-title
        router-link(img to="/")
          v-img(width="180px" height="auto" background-size="cover" src="../assets/img/logo.svg" contain)
      v-spacer
      .hidden-sm-and-down
        v-menu(v-for='item in items' :key='item.id')
          template(v-slot:activator='{ on: menu, attrs }')
            v-tooltip(bottom='')
              template(v-slot:activator='{ on: tooltip }')
                v-btn(fab icon v-bind='attrs' v-on='{ ...tooltip}' :to="item.url")
                  v-icon {{ item.icon }}
              span {{ item.text }}
      v-menu(transition="slide-y-transition"
        bottom
        left
        offset-y)
        template(v-slot:activator='{ on, attrs }')
          v-btn(icon='' v-bind='attrs' v-on='on').d-lg-none.d-md-none
            v-icon mdi-menu
        v-list
          v-list-item-group(v-model='model')
            v-list-item(v-for='item in items' :key='item.id' :to="item.url")
              v-list-item-icon
                v-icon(v-text='item.icon')
              v-list-item-content
                v-list-item-title(v-text='item.text')
    router-view
</template>
<script>
export default {
  data: () => ({
    items: [
      {
        icon: 'mdi-certificate-outline',
        text: 'Certificados',
        url: '/certificados'
      }
    ],
    model: 1
  })
}
</script>
<style>
</style>
