<template lang='pug'>
  section#features
    .container.mt-15.mb-15
      .row.px-4
        .col-md-6.mx-auto.text-center
          h2.accent--text.font-weight-bold Aprende con nosotros
          p.font-weight-light
            | Con nuestros cursos para ni&ntilde;os online estamos aprovechando toda nuestra
            | experiencia para que tu hijo o hija sea capaz de aprender los conceptos m&aacute;s importantes. Adem&aacute;s
            | la modalidad de clases en directo por videoconferencia con un profesor capacitado son un
            | garant&iacute;a de tranquilidad para padres y alumnos.
      .row
        .col-md-6.mx-auto.text-center
          .d-flex.justify-center.my-4
            v-icon( x-large color="primary") mdi-cast-education
          h4
            | Online
          p.font-weight-light
            | Los cursos son 100% en línea, con clases en vivo vía Zoom.
        .col-md-6.mx-auto.text-center
          .d-flex.justify-center.my-4
            v-icon(x-large color="secondary") mdi-tools
          h4
            | Proyectos
          p.font-weight-light
            | Cursos 100% prácticos, donde se desarrollan varios proyectos.
        .col-md-6.mx-auto.text-center
          .d-flex.justify-center.my-4
            v-icon(x-large color="success") mdi-rocket
          h4
            | Futuro
          p.font-weight-light
            | Formamos futuros genios en áreas STEAM.
        .col-md-6.mx-auto.text-center
          .d-flex.justify-center.my-4
            v-icon(x-large color="accent") mdi-bag-personal
          h4
            | Refuerza
          p.font-weight-light
            | Contenidos complementarios para seguir estudiando.
        .col-md-6.mr-auto.mt-4
          h2.font-weight-medium Trunable es mas que una academia.
          p.font-weight-normal
            | Trunable brinda una propuesta innovadora, orientado a niños.
            | Aprender Ciencia, Tecnología, Ingeniería, Arte y Matemáticas no tiene por
            | que ser una tarea pesada. TRUNABLE - inpiramos futuro
        .col-md-5.mx-auto.mt-4
          template
            v-carousel(:show-arrows='false' height="250" cycle show-arrows-on-hover hide-delimiter-background)
              v-carousel-item(v-for='(item,i) in items' :key='i' :src='item.src')
    router-view
</template>
<script>
export default {
  data () {
    return {
      items: [
        {
          src: require('../assets/img/slide_4.jpg')
        },
        {
          src: require('../assets/img/slide_2.jpg')
        },
        {
          src: require('../assets/img/slide_3.jpg')
        },
        {
          src: require('../assets/img/slide_1.jpg')
        }
      ]
    }
  }
}
</script>
<style>
</style>
