<template lang='pug'>
  div
    inicio
    aprende
    preguntas
    cursos
    pie
</template>

<script>
import inicio from '@/components/inicio.vue'
import aprende from '@/components/aprende.vue'
import preguntas from '@/components/preguntas.vue'
import cursos from '@/components/cursos.vue'
import pie from '@/components/pie.vue'
export default {
  name: 'Home',
  components: {
    inicio,
    aprende,
    preguntas,
    cursos,
    pie
  }
}
</script>
<style>
  .container{
    max-width: 1240px;
  }
</style>>
