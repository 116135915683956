import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.indigo.accent4, // #E53935
        secondary: colors.amber.darken1, // #FFCDD2
        accent: colors.pink.base, // #3F51B5
        error: '#FF5252',
        info: '#2196F3',
        success: colors.green.accent4,
        warning: '#FFC107'
      }
    }
  }
})
